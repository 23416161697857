import React from 'react'
import {SidePanelContent} from '../../../reducers/side-panel'
import {isWebinar} from '../../../selectors/session'
import {isChatMessagesPending, isChatOpened} from '../../../selectors/settings'
import {Tooltip} from '../../common/tooltip'
import {ControlButton} from '../../control-button'
import {Reactions} from '../../controls/reactions'
import {useMediaDevicesContext} from '../../media-devices-provider/media-devices-provider'
import {useActions, useWidgetState} from '../../widget-state-provider'
import {
  MicMuted,
  MicNotMuted,
  CameraMuted,
  CameraNotMuted,
  ChatNotClicked,
  ReactionsClicked,
  ReactionsNotClicked,
  ChatClicked,
} from '../../icons'
import {useWidgetBI} from '../../../hooks/bi'
import {DH} from '../../../../data-hooks'
import s from './controls.scss'
import {ControlsProps} from './index'

export const Controls: React.FC<ControlsProps> = () => {
  const webinar = useWidgetState(isWebinar)
  const {
    controls: {audio, video},
    sidePanel: sidePanelState,
  } = useWidgetState(state => state)
  const chatMessagesPending = useWidgetState(state => isChatMessagesPending(state.sidePanel))
  const {setVideo, setAudio, toggleSidePanel} = useActions()
  const [reactionsOpened, setReactionsOpened] = React.useState(false)
  const {uouLiveVideoMicrophoneToggle, uouLiveVideoCameraToggle, uouLiveVideoChatScreenToggle} = useWidgetBI()
  const chatOpened = isChatOpened(sidePanelState)
  const {devices} = useMediaDevicesContext()

  const onVideoClicked = () => {
    if (devices.cameras.length) {
      const newVideo = !video
      setVideo(newVideo)
      uouLiveVideoCameraToggle(newVideo ? 'on' : 'off')
    } else {
      toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)
    }
  }

  const onMicClicked = () => {
    if (devices.microphones.length) {
      const newAudio = !audio
      setAudio(newAudio)
      uouLiveVideoMicrophoneToggle(newAudio ? 'on' : 'off')
    } else {
      toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)
    }
  }

  const onChatClicked = () => {
    uouLiveVideoChatScreenToggle(chatOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.CHAT)
  }

  return (
    <div className={s.container}>
      {!webinar && (
        <>
          <ControlButton
            dataHook={DH.AUDIO_TOGGLE}
            onClick={onMicClicked}
            Icon={audio ? MicNotMuted : MicMuted}
            active={audio}
          />
          <ControlButton
            dataHook={DH.VIDEO_TOGGLE}
            onClick={onVideoClicked}
            Icon={video ? CameraNotMuted : CameraMuted}
            active={video}
          />
        </>
      )}
      {webinar ? (
        <ControlButton>
          <Reactions />
        </ControlButton>
      ) : (
        <Tooltip
          className={s.mobileReactions}
          dataHook={DH.REACTIONS_TOGGLE}
          placement="top"
          trigger="click"
          tooltip={<Reactions />}
          showArrow={false}
          onVisibilityChange={() => setReactionsOpened(!reactionsOpened)}
        >
          <ControlButton active={reactionsOpened} Icon={reactionsOpened ? ReactionsClicked : ReactionsNotClicked} />
        </Tooltip>
      )}

      <ControlButton
        dataHook={DH.CHAT_TOGGLE}
        onClick={onChatClicked}
        active={chatOpened}
        Icon={chatOpened ? ChatClicked : ChatNotClicked}
        pimple={chatMessagesPending}
      />
    </div>
  )
}
