import React from 'react'

export const CameraNotMuted: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Camera Not Muted</title>
    <g id="Icon/Camera-Not-Muted" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="09-Icons-/-General-/-Video" transform="translate(3.000000, 6.000000)">
        <path
          d="M11.5,0.5 L11.5,11.5 L0.5,11.5 L0.5,0.5 L11.5,0.5 Z M17.5,2.80901699 L17.5,9.19098301 L14.5,7.69098301 L14.5,4.30901699 L17.5,2.80901699 Z"
          id="Combined-Shape"
          stroke="#000000"
        ></path>
        <path
          d="M16.7965278,3.99118007 L16.7965278,8.1695476 L15.3230624,7.44965074 L15.3230624,4.74194263 L16.7965278,3.99118007 Z"
          id="Path"
          stroke="#000000"
          fill="#000000"
        ></path>
        <rect id="Rectangle" fill="#000000" x="1" y="1" width="10" height="10"></rect>
      </g>
    </g>
  </svg>
)
