import React from 'react'
import classNames from 'classnames'
import {useWidgetState} from '../../widget-state-provider'
import {DH} from '../../../../data-hooks'
import {isChatOpened} from '../../../selectors/settings'
import {useRecorder} from '../../../hooks/recorder'
import {NotificationsProps} from './interfaces'
import s from './notifications.scss'
import {Notification} from './notification'

export const Notifications: React.FC<NotificationsProps> = ({mobile}) => {
  const {notifications} = useWidgetState(state => state.notifications)
  const chatOpened = useWidgetState(state => isChatOpened(state.sidePanel))
  const {recorder} = useRecorder()

  if (recorder || !notifications.length || (mobile && chatOpened)) {
    return null
  }

  return (
    <div data-hook={DH.NOTIFICATIONS} className={classNames(s.container, {[s.mobile]: mobile})}>
      {notifications.map(notification => (
        <Notification key={notification.id} notification={notification} mobile={mobile} />
      ))}
    </div>
  )
}
