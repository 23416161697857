import React from 'react'

export const ViewersClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Viewers Clicked</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
    </defs>
    <g id="Icon/Viewers-Clicked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-2" fill="white">
        <use href="#path-1"></use>
      </mask>
      <g id="Background"></g>
      <path
        d="M10,13 C11.6568542,13 13,14.3431458 13,16 L13,20 L3,20 L3,16 C3,14.3431458 4.34314575,13 6,13 L10,13 Z M18,11 C19.6568542,11 21,12.3431458 21,14 L21,18 L13.9999837,18 L14,15 C14,13.5823804 13.0167274,12.3944164 11.6949837,12.0809097 C12.2443951,11.4203513 13.0731084,11 14,11 L18,11 Z M8,6 C9.65685425,6 11,7.34314575 11,9 C11,10.6568542 9.65685425,12 8,12 C6.34314575,12 5,10.6568542 5,9 C5,7.34314575 6.34314575,6 8,6 Z M16,4 C17.6568542,4 19,5.34314575 19,7 C19,8.65685425 17.6568542,10 16,10 C14.3431458,10 13,8.65685425 13,7 C13,5.34314575 14.3431458,4 16,4 Z"
        id="Combined-Shape"
        fill="#000000"
        fill-rule="nonzero"
        mask="url(#mask-2)"
      ></path>
    </g>
  </svg>
)
