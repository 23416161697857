import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import s from 'react-popper-tooltip/dist/styles.css'
import classNames from 'classnames'

export const Tooltip = ({
  children,
  tooltip,
  onVisibilityChange,
  className = null,
  triggerClassName = null,
  showArrow = true,
  dataHook,
  ...props
}) => (
  <TooltipTrigger
    onVisibilityChange={onVisibilityChange}
    {...props}
    tooltip={({arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement}) => (
      <div
        className={classNames(s.tooltipContainer, className)}
        {...getTooltipProps({
          ref: tooltipRef,
        })}
      >
        {showArrow && (
          <div
            className={s.tooltipArrow}
            {...getArrowProps({
              ref: arrowRef,
              'data-placement': placement,
            })}
          />
        )}
        {tooltip}
      </div>
    )}
    usePortal={false}
  >
    {({getTriggerProps, triggerRef}) => (
      <span
        data-hook={dataHook}
        className={classNames(s.trigger, triggerClassName)}
        {...getTriggerProps({
          ref: triggerRef,
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
)
