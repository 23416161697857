import {Device, GetDevicesResponse, isIOS, isSafari, isUnsupportedBrowser} from '@wix/live-video-commons'
import {useMediaPermissions} from '@wix/live-video-components'
import React, {useEffect, useState} from 'react'
import {isWebinar} from '../../selectors/session'
import {useActions, useWidgetState} from '../widget-state-provider'

interface MediaDevicesProviderState {
  hasPermissions: boolean
  permissionsDenied: boolean
  selectedCamera: string
  devices: GetDevicesResponse
  selectedSpeaker: string
  selectedMicrophone: string
  IOSBrowserNotSupported: boolean
  unsupportedBrowser: boolean
  setCamera: React.Dispatch<React.SetStateAction<string>>
  setMicrophone: React.Dispatch<React.SetStateAction<string>>
  setSpeaker: React.Dispatch<React.SetStateAction<string>>
  requestPermissions: () => void
}

const MediaDevicesContext = React.createContext<MediaDevicesProviderState>(null)

interface MediaDevicesProviderProps {
  ssr: boolean
  previewMode: boolean
}

export const MediaDevicesProvider: React.FC<MediaDevicesProviderProps> = ({children, ssr, previewMode}) => {
  const IOSBrowserNotSupported = isIOS() && !isSafari()
  const unsupportedBrowser = !ssr && isUnsupportedBrowser()
  const {permissionsDenied, requestPermissions, devices, hasPermissions} = useMediaPermissions()
  const webinar = useWidgetState(isWebinar)
  const {setVideo, setAudio} = useActions()
  const [camera, setCamera] = useState<string>(null)
  const [microphone, setMicrophone] = useState<string>(null)
  const [speaker, setSpeaker] = useState<string>(null)

  useEffect(() => {
    if (!(ssr || IOSBrowserNotSupported || previewMode || webinar)) {
      requestPermissions()
    }
  }, [ssr, webinar, previewMode])

  useEffect(() => {
    if (permissionsDenied) {
      setVideo(false)
      setAudio(false)
    }
  }, [permissionsDenied])

  useEffect(() => {
    if (previewMode) {
      setVideo(false)
      setAudio(false)
    }
  }, [previewMode])

  useEffect(() => {
    setCamera(defaultDeviceIfUnselected(devices.cameras, camera))
    setMicrophone(defaultDeviceIfUnselected(devices.microphones, microphone))
    setSpeaker(defaultDeviceIfUnselected(devices.speakers, speaker))
  }, [devices])

  const selectedCamera = defaultDeviceIfUnselected(devices.cameras, camera)
  const selectedMicrophone = defaultDeviceIfUnselected(devices.microphones, microphone)
  const selectedSpeaker = defaultDeviceIfUnselected(devices.speakers, speaker)

  return (
    <MediaDevicesContext.Provider
      value={{
        selectedCamera,
        selectedMicrophone,
        selectedSpeaker,
        IOSBrowserNotSupported,
        unsupportedBrowser,
        setCamera,
        setMicrophone,
        setSpeaker,
        hasPermissions,
        permissionsDenied,
        devices,
        requestPermissions,
      }}
    >
      {children}
    </MediaDevicesContext.Provider>
  )
}

export function useMediaDevicesContext() {
  return React.useContext<MediaDevicesProviderState>(MediaDevicesContext)
}

const defaultDeviceIfUnselected = (changedDevices: Device[] = [], selectedDeviceId) => {
  const selectedDevice = changedDevices.find(device => device.id === selectedDeviceId)
  if (selectedDevice) {
    return selectedDeviceId
  }
  return changedDevices[0]?.id ?? null
}
