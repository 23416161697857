import React from 'react'
import {Exit} from 'wix-ui-icons-common'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DH} from '../../../../data-hooks'
import {useWidgetBI} from '../../../hooks/bi'
import {SidePanelContent} from '../../../reducers/side-panel'
import {getActiveParticipantCount} from '../../../selectors/participants'
import {isWebinar} from '../../../selectors/session'
import {isMobileSettingsOpened, isParticipantsOpened} from '../../../selectors/settings'
import {ControlButton} from '../../control-button'
import {SettingsClicked, SettingsNotClicked, ViewersClicked, ViewersNotClicked} from '../../icons'
import {useActions, useWidgetState} from '../../widget-state-provider'
import {Recording} from '../../icons/recording/recording'
import {useRecorder} from '../../../hooks/recorder'
import s from './header.scss'

export const Header: React.FC = () => {
  const {t} = useTranslation()
  const webinar = useWidgetState(isWebinar)
  const sidePanelState = useWidgetState(state => state.sidePanel)
  const participantsOpened = isParticipantsOpened(sidePanelState)
  const settingsOpened = isMobileSettingsOpened(sidePanelState)
  const activeParticipantCount = useWidgetState(getActiveParticipantCount)
  const {toggleSidePanel, leaveSession: leaveSessionAction} = useActions()
  const {uouLiveVideoParticipantsListToggle, uouLiveVideoSettingsToggle, uouLiveVideoLeaveClick} = useWidgetBI()
  const {recorderJoined} = useRecorder()

  const openParticipants = () => {
    uouLiveVideoParticipantsListToggle(participantsOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.PARTICIPANTS)
  }

  const openSettings = () => {
    uouLiveVideoSettingsToggle(settingsOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.MOBILE_SETTINGS)
  }

  const leaveSession = () => {
    uouLiveVideoLeaveClick()
    leaveSessionAction()
  }

  return (
    <div className={s.container}>
      <div className={s.left}>
        <ControlButton
          dataHook={DH.PARTICIPANTS_TOGGLE}
          small
          square
          Icon={participantsOpened ? ViewersClicked : ViewersNotClicked}
          active={participantsOpened}
          text={activeParticipantCount}
          textClassName={s.controlButtonText}
          onClick={openParticipants}
        />
        {recorderJoined && (
          <div className={s.recording}>
            <Recording animated />
            <div className={s.recordingText}>{t('recordingAbbrev')}</div>
          </div>
        )}
      </div>
      <div className={s.right}>
        {!webinar && (
          <ControlButton
            dataHook={DH.SETTINGS_TOGGLE}
            small
            square
            Icon={settingsOpened ? SettingsClicked : SettingsNotClicked}
            active={settingsOpened}
            onClick={openSettings}
          />
        )}
        <ControlButton dataHook={DH.LEAVE_BUTTON} small square red Icon={Exit} onClick={leaveSession} />
      </div>
    </div>
  )
}
