import React from 'react'

export const MicNotMuted: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Mic Not Muted</title>
    <g id="Icon/Mic-Not-Muted" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-12" transform="translate(6.000000, 4.000000)" fill="#000000" fill-rule="nonzero">
        <path
          d="M3,17 L3,16 L6,16 L6.00067087,13.9811062 C2.64412846,13.7261164 0,10.9218429 0,7.5 L0,7 L1,7 L1,7.5 C1,10.5375661 3.46243388,13 6.5,13 C9.53756612,13 12,10.5375661 12,7.5 L12,7 L13,7 L13,7.5 C13,10.9215021 10.3563982,13.7255578 7.00033196,13.9810299 L7,16 L10,16 L10,17 L3,17 Z M6.5,0 C8.98528137,0 11,2.01471863 11,4.5 L11,7.5 C11,9.98528137 8.98528137,12 6.5,12 C4.01471863,12 2,9.98528137 2,7.5 L2,4.5 C2,2.01471863 4.01471863,0 6.5,0 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
)
