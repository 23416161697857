import React from 'react'
import {LAYOUT} from '@wix/live-video-commons'
import {Settings} from 'wix-ui-icons-common'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {useWidgetBI} from '../../../hooks/bi'
import {SidePanelContent} from '../../../reducers/side-panel'
import {useActions, useWidgetState} from '../../widget-state-provider'
import {GridPopover, SpeakerViewPopover} from '../../icons'
import s from './mobile-settings.scss'
import {ItemProps} from '.'

export const MobileSettings: React.FC = () => {
  const {t} = useTranslation()
  const {layout} = useWidgetState(state => state.controls)
  const {setLayout, toggleSidePanel} = useActions()
  const {uouLiveVideoChangeLayout} = useWidgetBI()
  const gridSelected = layout === LAYOUT.GRID

  const changeLayout = () => {
    uouLiveVideoChangeLayout(gridSelected ? 'speaker' : 'grid')
    setLayout(gridSelected ? LAYOUT.FOCUSED : LAYOUT.GRID)
    toggleSidePanel()
  }

  const openCameraAndSoundSettings = () => toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)

  return (
    <div className={s.container}>
      <Item
        Icon={gridSelected ? SpeakerViewPopover : GridPopover}
        label={t(gridSelected ? 'settings.mobile.speakerView' : 'settings.mobile.gridView')}
        onClick={changeLayout}
      />
      <Item Icon={Settings} label={t('settings.mobile.cameraSound')} onClick={openCameraAndSoundSettings} />
    </div>
  )
}

const Item: React.FC<ItemProps> = ({Icon, label, onClick}) => (
  <button className={s.item} onClick={onClick}>
    <Icon />
    <div className={s.label}>{label}</div>
  </button>
)
