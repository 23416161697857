import React from 'react'
import {ChatMessage, Participant} from '@wix/ambassador-livevideo-server/types'
import {getAvatarColor} from '@wix/live-video-commons'
import {TFunction} from '@wix/yoshi-flow-editor'
import {Message} from '../message'
import {getLabeledParticipantName} from '../../../../selectors/participants'
import {isMessagePending} from '../temporary-message-id'

interface MessagesProps {
  messages: ChatMessage[]
  participants: Participant[]
  myParticipantId: string
  t: TFunction
}

const CHAIN_MAX_TIME_DIFF = 5 * 60 * 1000

export const Messages: React.FC<MessagesProps> = ({messages, participants, myParticipantId, t}) => {
  const shouldMessageBeChained = (messageIndex: number) => {
    if (messageIndex === 0) {
      return false
    }
    const message = messages[messageIndex]
    const pastMessage = messages[messageIndex - 1]
    if (message.participantId !== pastMessage.participantId) {
      return false
    }
    if (Number(new Date(message.created)) - Number(new Date(pastMessage.created)) > CHAIN_MAX_TIME_DIFF) {
      return false
    }
    return true
  }

  return (
    <>
      {messages.map(({id, body, created, participantId}, index) => (
        <Message
          key={id}
          body={body}
          created={String(created)}
          name={getLabeledParticipantName(participants, participantId, myParticipantId, t)}
          own={participantId === myParticipantId}
          avatarColor={getAvatarColor(participantId)}
          chained={shouldMessageBeChained(index)}
          pending={isMessagePending(id)}
        />
      ))}
    </>
  )
}
