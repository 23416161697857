import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {GenericModal} from '../generic-modal'
import {TestVideoParticipantLimitModalProps} from '.'

export const TestVideoParticipantLimitModal = ({onClose}: TestVideoParticipantLimitModalProps) => {
  const {t} = useTranslation()

  return <GenericModal title={t('modal.testVideoParticipantLimit.title')} onClose={onClose} />
}
