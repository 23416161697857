import React from 'react'
import {getAvatarColor} from '@wix/live-video-commons'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {useWidgetState} from '../../widget-state-provider'
import {
  getActiveGuestParticipants,
  getActiveHostParticipants,
  getParticipantLabel,
  getParticipantStatus,
  getParticipantName,
} from '../../../selectors/participants'
import {getMyParticipantId, isWebinar} from '../../../selectors/session'
import {List} from './list'
import {Participant} from './participant'
import s from './participants.scss'

export const Participants = () => {
  const hosts = useWidgetState(getActiveHostParticipants)
  const guests = useWidgetState(getActiveGuestParticipants)

  return (
    <div className={s.root}>
      <div className={s.container}>
        {Boolean(hosts.length) && (
          <>
            <ParticipantList className={s.hosts} participants={hosts} />
            <div className={s.divider} />
          </>
        )}
        <ParticipantList className={s.guests} participants={guests} />
      </div>
    </div>
  )
}

const ParticipantList = ({participants, className}) => {
  const {t} = useTranslation()
  const state = useWidgetState(allState => allState)
  const myParticipantId = useWidgetState(getMyParticipantId)
  const webinar = useWidgetState(isWebinar)

  return (
    <div className={className}>
      <List>
        {participants.map(participant => {
          const {muted, video, screenshare, reaction} = getParticipantStatus(state, participant.participantId) || {}
          return (
            <Participant
              colorId={getAvatarColor(participant.participantId)}
              name={getParticipantName(participants, participant.participantId)}
              label={getParticipantLabel(participants, participant.participantId, myParticipantId, t)}
              muted={muted}
              video={video}
              screenshare={screenshare}
              webinar={webinar}
              reaction={reaction?.emoji}
            />
          )
        })}
      </List>
    </div>
  )
}
