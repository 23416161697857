import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {useActions, useWidgetState} from '../../widget-state-provider'
import {useApi} from '../../api-provider'
import {getMyParticipantId} from '../../../selectors/session'
import {useWidgetBI} from '../../../hooks/bi'
import s from './chat.scss'
import {Input} from './input'
import {Messages} from './messages'
import {useMessageHistoryChanged, useScrollControls} from './hooks'
import {ScrollButton} from './scroll-button'
import {EmptyState} from './empty-state'
import {getTemporaryMessageId} from './temporary-message-id'
import {ChatProps} from './index'

export const Chat: React.FC<ChatProps> = () => {
  const {t} = useTranslation()
  const {messages, hasMoreHistory} = useWidgetState(state => state.chat)
  const {authorization} = useWidgetState(state => state.session)
  const myParticipantId = useWidgetState(getMyParticipantId)
  const {participants} = useWidgetState(state => state.participants)
  const {fetchChatHistory, receiveChatMessage, addPendingChatMessage, removePendingChatMessage} = useActions()
  const api = useApi()
  const {
    atBottom,
    scrollToBottom,
    scrollToPrevious,
    checkScrolledToBottom,
    setLoaded,
    containerRef: scrollContainerRef,
  } = useScrollControls(hasMoreHistory ? fetchChatHistory : null)
  const {uouLiveVideoChatMessagePublish} = useWidgetBI()

  const [newMessage, setNewMessage] = useState('')
  const historyChanged = useMessageHistoryChanged(messages)

  const sendNewMessage = useCallback(() => {
    if (newMessage.trim()) {
      uouLiveVideoChatMessagePublish()
      setNewMessage('')
      const pendingId = getTemporaryMessageId()
      addPendingChatMessage({body: newMessage, participantId: myParticipantId, id: pendingId})
      api
        .sendChatMessage(authorization, newMessage)
        .then(({message}) => {
          receiveChatMessage({message, pendingId})
        })
        .catch(e => {
          removePendingChatMessage(pendingId)
          throw e
        })
    }
  }, [newMessage])

  useEffect(() => {
    if (historyChanged) {
      setLoaded()
      if (!atBottom) {
        scrollToPrevious()
      }
    }
  }, [historyChanged, atBottom])

  useEffect(() => {
    if (atBottom) {
      scrollToBottom()
    } else {
      checkScrolledToBottom()
    }
  }, [messages.length, atBottom])

  useEffect(() => {
    scrollToBottom()
  }, [])

  return (
    <div className={s.chat}>
      <div className={s.messages} ref={scrollContainerRef}>
        {messages.length ? (
          <>
            <Messages messages={messages} participants={participants} myParticipantId={myParticipantId} t={t} />
            {!atBottom && (
              <div className={s.backToBottom}>
                <ScrollButton onClick={scrollToBottom} />
              </div>
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </div>
      <div className={s.controls}>
        <Input value={newMessage} onChange={setNewMessage} onSubmit={sendNewMessage} />
      </div>
    </div>
  )
}
