export const DH = {
  JOIN_BUTTON: 'JOIN_BUTTON',
  REFRESH_BUTTON: 'REFRESH_BUTTON',
  REQUEST_PERMISSIONS: 'REQUEST_PERMISSIONS',
  VIDEO_PREVIEW: 'VIDEO_PREVIEW',
  REACTIONS_CONTAINER: 'REACTIONS_CONTAINER',
  CLOSE_SIDE_PANEL: 'CLOSE_SIDE_PANEL',
  CHAT_INPUT: 'CHAT_INPUT',
  SENDER_NAME: 'SENDER_NAME',
  MESSAGE_TIME_AGO: 'MESSAGE_TIME_AGO',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  CHAT_MESSAGE_TEXT: 'CHAT_MESSAGE_TEXT',
  NAME_INPUT: 'NAME_INPUT',
  REACTION: 'REACTION',
  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATION_TEXT: 'NOTIFICATION_TEXT',
  NOTIFICATION_REACTION: 'NOTIFICATION_REACTION',
  NOTIFICATION_PARTICIPANT_NAME: 'NOTIFICATION_PARTICIPANT_NAME',
  NOTIFICATIONS: 'NOTIFICATIONS',
  AUDIO_TOGGLE: 'AUDIO_TOGGLE',
  VIDEO_TOGGLE: 'VIDEO_TOGGLE',
  SCREEN_SHARE_TOGGLE: 'SCREEN_SHARE_TOGGLE',
  REACTIONS_TOGGLE: 'REACTIONS_TOGGLE',
  CHAT_TOGGLE: 'CHAT_TOGGLE',
  PARTICIPANTS_TOGGLE: 'PARTICIPANTS_TOGGLE',
  SETTINGS_TOGGLE: 'SETTINGS_TOGGLE',
  LAYOUT_TOGGLE: 'LAYOUT_TOGGLE',
  LEAVE_BUTTON: 'LEAVE_BUTTON',
  MAIN_TAB: 'MAIN_TAB',
  DESIGN_TAB: 'DESIGN_TAB',
  SUPPORT_TAB: 'SUPPORT_TAB',
  SUPPORT_TAB_EMAIL: 'SUPPORT_TAB_EMAIL',
  SUPPORT_TAB_SUPPORT: 'SUPPORT_TAB_SUPPORT',
  STATUS_SCREEN_TITLE: 'STATUS_SCREEN_TITLE',
  STATUS_SCREEN_SUBTITLE: 'STATUS_SCREEN_SUBTITLE',
  STATUS_SCREEN_REJOIN: 'STATUS_SCREEN_REJOIN',
  STATUS_SCREEN_GO_TO_SITE: 'STATUS_SCREEN_GO_TO_SITE',
  PARTICIPANT: 'PARTICIPANT',
  PARTICIPANT_NAME: 'PARTICIPANT_NAME',
  PARTICIPANT_LABEL: 'PARTICIPANT_LABEL',
}
