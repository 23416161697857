import React from 'react'
import classNames from 'classnames'
import {MessageTime, ChatMessage} from '@wix/live-video-components'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DH} from '../../../../../data-hooks'
import {Avatar} from '../../../common/avatar'
import s from './message.scss'
import {MessageProps} from './index'

export const Message: React.FC<MessageProps> = ({name, created, body, own, chained, avatarColor, pending}) => {
  const {t} = useTranslation()
  return (
    <div className={classNames(s.root, chained ? s.chained : null)} data-hook={DH.CHAT_MESSAGE}>
      {!chained && (
        <div className={s.header}>
          <Avatar colorId={avatarColor} name={name} />
          <span data-hook={DH.SENDER_NAME} className={s.name}>
            {name}
          </span>
          <span data-hook={DH.MESSAGE_TIME_AGO} className={s.timeAgo}>
            {pending ? (
              t('chat.message.pending')
            ) : (
              <MessageTime date={new Date(created)} justNowText={t('chat.message.justNow')} lang="en" />
            )}
          </span>
        </div>
      )}
      <div className={classNames(s.content, {[s.own]: own, [s.pending]: pending})}>
        <ChatMessage>{body}</ChatMessage>
      </div>
    </div>
  )
}
