import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {ButtonPriority as PRIORITY, ButtonSize as SIZE} from 'wix-ui-tpa'
import screenfull from 'screenfull'
import {UouLiveVideoExitScreenLoadedReason, useWidgetBI} from '../../hooks/bi'
import {getInitialParticipantName} from '../../selectors/participants'
import {getSession, isWebinar} from '../../selectors/session'
import {Button} from '../common/button'
import {useActions, useWidgetState} from '../widget-state-provider'
import {DH} from '../../../data-hooks'
import {useRecorder} from '../../hooks/recorder'
import s from './status-screen.scss'
import {StatusScreenProps, StatusScreenType} from '.'

export const StatusScreen: React.FC<StatusScreenProps> = ({type}) => {
  const {isMobile} = useEnvironment()
  const session = useWidgetState(getSession)
  const {uouLiveVideoExitScreenLoaded} = useWidgetBI()
  const {title, mainAction, secondaryAction} = useContent(type)

  useEffect(() => {
    let reason: UouLiveVideoExitScreenLoadedReason = 'end'

    if (type === StatusScreenType.LEFT_SESSION) {
      reason = 'leave'
    }

    if (type === StatusScreenType.BANNED) {
      reason = 'banned'
    }

    if (type === StatusScreenType.JOINED_ELSEWHERE) {
      reason = 'joined_elsewhere'
    }

    uouLiveVideoExitScreenLoaded(reason)
  }, [])

  return (
    <div className={classNames(s.container, {[s.mobile]: isMobile})}>
      <div className={s.title} data-hook={DH.STATUS_SCREEN_TITLE}>
        {title}
      </div>
      <div className={s.subtitle} data-hook={DH.STATUS_SCREEN_SUBTITLE}>
        {session.name}
      </div>
      {(mainAction || secondaryAction) && (
        <div className={s.buttons}>
          {secondaryAction && (
            <Button
              data-hook={secondaryAction.dataHook}
              className={s.secondaryButton}
              priority={PRIORITY.secondary}
              size={isMobile ? SIZE.medium : SIZE.large}
              fullWidth={isMobile}
              onClick={secondaryAction.onClick}
            >
              {secondaryAction.label}
            </Button>
          )}
          <Button
            data-hook={mainAction.dataHook}
            className={s.primaryButton}
            size={isMobile ? SIZE.medium : SIZE.large}
            fullWidth={isMobile}
            onClick={mainAction.onClick}
          >
            {mainAction.label}
          </Button>
        </div>
      )}
    </div>
  )
}

const useContent = (type: StatusScreenType) => {
  const {t} = useTranslation()
  const {addParticipant, navigateToMainPage, resetJoinedOnAnotherDevice} = useActions()
  const {uouLiveVideoGoToSiteClick, uouLiveVideoRejoinClick} = useWidgetBI()
  const webinar = useWidgetState(isWebinar)
  const name = useWidgetState(getInitialParticipantName)
  const {recorder} = useRecorder()

  const goToSiteAction = {
    dataHook: DH.STATUS_SCREEN_GO_TO_SITE,
    label: t('statusScreen.action.goToSite'),
    onClick: () => {
      uouLiveVideoGoToSiteClick()
      if (screenfull.isEnabled) {
        screenfull.exit()
      }
      navigateToMainPage()
    },
  }

  const rejoinAction = {
    dataHook: DH.STATUS_SCREEN_REJOIN,
    label: t('statusScreen.action.rejoin'),
    onClick: async () => {
      uouLiveVideoRejoinClick()
      await addParticipant({name})
      resetJoinedOnAnotherDevice()
    },
  }

  switch (type) {
    case StatusScreenType.JOINED_ELSEWHERE:
      return {
        title: t('statusScreen.joinedElsewhere.title'),
        mainAction: rejoinAction,
        secondaryAction: goToSiteAction,
      }
    case StatusScreenType.SESSION_ENDED:
      return {
        title: t(webinar ? 'statusScreen.webinarEnded.title' : 'statusScreen.meetingEnded.title'),
        mainAction: goToSiteAction,
      }
    case StatusScreenType.LEFT_SESSION:
      if (recorder) {
        return {
          title: t('statusScreen.recordingEnded.title'),
        }
      } else {
        return {
          title: t(webinar ? 'statusScreen.leftWebinar.title' : 'statusScreen.leftMeeting.title'),
          mainAction: rejoinAction,
          secondaryAction: goToSiteAction,
        }
      }
    case StatusScreenType.BANNED:
      return {
        title: t(webinar ? 'statusScreen.bannedWebinar.title' : 'statusScreen.bannedMeeting.title'),
        mainAction: goToSiteAction,
      }
    default:
      return {}
  }
}
