import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {
  isBackgroundSettingsOpened,
  isCameraAndSoundSettingsOpened,
  isChatOpened,
  isMobileSettingsOpened,
  isNameSettingsOpened,
  isParticipantsOpened,
  isSidePanelOpened,
} from '../../selectors/settings'
import {useActions, useWidgetState} from '../widget-state-provider'
import {SidePanelContent} from '../../reducers/side-panel'
import {getActiveParticipantCount} from '../../selectors/participants'
import {useWidgetBI} from '../../hooks/bi'
import {SidePanelContainer} from './side-panel-container'
import {Name} from './name'
import {Participants} from './participants'
import {Background} from './background'
import {CameraAndSound} from './camera-and-sound'
import {Chat} from './chat'
import {MobileSettings} from './mobile-settings'
import {SidePanelProps} from '.'

const HEADER = {
  [SidePanelContent.NAME]: (t: Function) => t('changeName'),
  [SidePanelContent.PARTICIPANTS]: (t: Function) => {
    const participantCount = useWidgetState(getActiveParticipantCount)
    return t('participantsCount', {count: participantCount})
  },
  [SidePanelContent.BACKGROUND]: (t: Function) => t('background'),
  [SidePanelContent.CAMERA_AND_SOUND]: (t: Function) => t('settings'),
  [SidePanelContent.CHAT]: (t: Function) => t('chat'),
  [SidePanelContent.MOBILE_SETTINGS]: (t: Function) => t('settings'),
}

export const SidePanel = ({className}: SidePanelProps) => {
  const sidePanel = useWidgetState(state => state.sidePanel)
  const chatOpened = isChatOpened(sidePanel)
  const settingsOpened = isCameraAndSoundSettingsOpened(sidePanel)
  const participantsOpened = isParticipantsOpened(sidePanel)
  const {toggleSidePanel} = useActions()
  const {uouLiveVideoChatScreenToggle, uouLiveVideoSettingsToggle, uouLiveVideoParticipantsListToggle} = useWidgetBI()
  const {t} = useTranslation()

  if (!isSidePanelOpened(sidePanel)) {
    return null
  }

  const onSidePanelClose = () => {
    if (chatOpened) {
      uouLiveVideoChatScreenToggle('off')
    }

    if (settingsOpened) {
      uouLiveVideoSettingsToggle('off')
    }

    if (participantsOpened) {
      uouLiveVideoParticipantsListToggle('off')
    }

    toggleSidePanel()
  }

  return (
    <SidePanelContainer className={className} title={HEADER[sidePanel.content](t)} onClose={onSidePanelClose}>
      {isNameSettingsOpened(sidePanel) && <Name />}
      {participantsOpened && <Participants />}
      {isBackgroundSettingsOpened(sidePanel) && <Background />}
      {settingsOpened && <CameraAndSound />}
      {chatOpened && <Chat />}
      {isMobileSettingsOpened(sidePanel) && <MobileSettings />}
    </SidePanelContainer>
  )
}
