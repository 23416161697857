import {useEffect, useRef, useState} from 'react'
import {ChatMessage} from '@wix/ambassador-livevideo-server/types'
import {throttle} from 'lodash'
import {usePrevious} from '../../../../utils/hooks'

const LOAD_MORE_MARGIN = 200

export const useMessageHistoryChanged = (messages: ChatMessage[]) => {
  const previousMessages = usePrevious(messages)
  return previousMessages && previousMessages.length !== messages.length && messages[0]?.id !== previousMessages[0]?.id
}

export const useScrollControls = loadMore => {
  const containerRef = useRef<HTMLDivElement>()
  const diffFromBottom = useRef(0)
  const fetching = useRef(false)
  const [atBottom, setAtBottom] = useState(true)

  const shouldLoadMore = () => {
    const {scrollTop} = containerRef.current
    return scrollTop < LOAD_MORE_MARGIN
  }

  const checkScrolledToBottom = () => {
    const {scrollTop, clientHeight, scrollHeight} = containerRef.current
    setAtBottom(Math.abs(scrollTop + clientHeight - scrollHeight) <= 1)
  }

  const scrollToBottom = () => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight
    setAtBottom(true)
  }

  const scrollToPrevious = () => {
    if (!atBottom) {
      const {scrollHeight: newScrollHeight} = containerRef.current
      containerRef.current.scrollTop = newScrollHeight - diffFromBottom.current
    }
  }

  const storeScrollPosition = () => {
    if (containerRef.current) {
      const {scrollTop, scrollHeight} = containerRef.current
      diffFromBottom.current = scrollHeight - scrollTop
    }
  }

  const setLoaded = () => {
    fetching.current = false
  }

  useEffect(() => {
    const throttledCallback = throttle(
      () => {
        if (shouldLoadMore() && !fetching.current) {
          fetching.current = true
          loadMore && loadMore()
        }
        checkScrolledToBottom()
      },
      100,
      {leading: false},
    )
    containerRef.current.addEventListener('scroll', throttledCallback)
    return () => containerRef?.current?.removeEventListener('scroll', throttledCallback)
  }, [containerRef, loadMore, atBottom])

  storeScrollPosition()

  return {
    containerRef,
    scrollToBottom,
    scrollToPrevious,
    checkScrolledToBottom,
    atBottom,
    setLoaded,
  }
}
