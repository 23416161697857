import React, {useEffect, useRef} from 'react'
import classNames from 'classnames'
import {AmazonVideo} from '../amazon-video'
import {Controls} from '../controls'
import {SidePanel} from '../side-panel'
import {useActions, useWidgetState} from '../widget-state-provider'
import {isParticipantRejoining, isWebinar} from '../../selectors/session'
import {SidePanelContent} from '../../reducers/side-panel'
import {useRecorder} from '../../hooks/recorder'
import {getActiveParticipantCount} from '../../selectors/participants'
import {Header} from './header'
import s from './video.scss'
import {Notifications} from './notifications'
import {VideoProps} from '.'

const RECORDER_LEAVE_TIMEOUT = 10000

export const Video: React.FC<VideoProps> = () => {
  const webinar = useWidgetState(isWebinar)
  const {openSidePanel, leaveSession} = useActions()
  const {recorder} = useRecorder()
  const recorderLeaveTimerRef = useRef(null)
  const activeParticipantCount = useWidgetState(getActiveParticipantCount)
  const rejoining = useWidgetState(isParticipantRejoining)

  useEffect(() => {
    if (webinar && !recorder) {
      openSidePanel(SidePanelContent.CHAT)
    }
  }, [])

  useEffect(() => {
    if (recorder && !activeParticipantCount) {
      recorderLeaveTimerRef.current = setTimeout(leaveSession, RECORDER_LEAVE_TIMEOUT)
    } else {
      clearTimeout(recorderLeaveTimerRef.current)
    }
  }, [activeParticipantCount])

  return (
    <div className={classNames(s.container, {[s.videoRecorder]: recorder})}>
      {!recorder && <Header />}
      <div className={s.content}>
        {!rejoining && <AmazonVideo />}
        <SidePanel className={s.sidePanel} />
      </div>
      <Notifications />
      {!recorder && <Controls />}
    </div>
  )
}
