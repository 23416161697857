import {useActions, useWidgetState} from '../components/widget-state-provider/widget-state-provider'

export const useModals = () => {
  const currentModal = useWidgetState(state => state.modals.modal)
  const {showModal, hideModal} = useActions()

  return {
    currentModal,
    showModal,
    hideModal,
  }
}
