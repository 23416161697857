import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {GenericModal} from '../generic-modal'
import {UnsupportedBrowserModalProps} from '.'

export const UnsupportedBrowserModal = ({onClose}: UnsupportedBrowserModalProps) => {
  const {t} = useTranslation()

  return (
    <GenericModal
      title={t('modal.unsupportedBrowser.title')}
      text={t('modal.unsupportedBrowser.text')}
      onClose={onClose}
    />
  )
}
