import {getAvatarColor, isIOS, LAYOUT} from '@wix/live-video-commons'
import {FakeVideoComponent, Video} from '@wix/live-video-components'
import React, {useMemo, useState} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {AvatarSize} from 'wix-ui-tpa'
import {useWidgetBI} from '../../hooks/bi'
import {getParticipantName, getKnownClients} from '../../selectors/participants'
import {isWebinar, isParticipantRejoining, getSessionDuplexerConfig} from '../../selectors/session'
import {isSidePanelOpened} from '../../selectors/settings'
import {JoinWithSound} from '../join-with-sound/join-with-sound'
import {useMediaDevicesContext} from '../media-devices-provider/media-devices-provider'
import {useActions, useWidgetState, useAuthorization} from '../widget-state-provider'
import {Avatar} from '../common/avatar'
import {useRecorder} from '../../hooks/recorder'

const END_REASON_JOINED_ON_ANOTHER_DEVICE = 2

export const AmazonVideo: React.FC = () => {
  const instance = useAuthorization()
  const duplexerConfig = useWidgetState(getSessionDuplexerConfig)
  const rejoining = useWidgetState(isParticipantRejoining)
  const {props, session, token} = useWidgetState(state => state.session)
  const {video, audio, layout} = useWidgetState(state => state.controls)
  const {participants} = useWidgetState(state => state.participants)
  const {selectedCamera, selectedSpeaker, selectedMicrophone, hasPermissions} = useMediaDevicesContext()
  const sidePanelOpened = useWidgetState(state => isSidePanelOpened(state.sidePanel))
  const webinar = useWidgetState(isWebinar)
  const knownClients = useWidgetState(getKnownClients)
  const {markScreenShared, markScreenShareLoading, getSession, markJoinedOnAnotherDevice, leaveSession} = useActions()
  const {uouLiveVideoShareScreenToggle} = useWidgetBI()
  const {isMobile, isViewer} = useEnvironment()
  const {t} = useTranslation()
  const [soundModalVisible, setSoundModalVisibility] = useState(false)
  const {recorder} = useRecorder()
  const [, setScreenShared] = useState<boolean>(false)

  const texts = useMemo(
    () => ({
      sharingScreen: t('sharingScreen'),
      stopSharing: t('stopSharing'),
      host: `(${t('participants.label.host')})`,
    }),
    [],
  )

  const getName = React.useCallback((id: string) => getParticipantName(participants, id), [participants])

  const renderAvatar = React.useCallback(
    (id: string, className: string) => (
      <Avatar
        name={getName(id)}
        colorId={getAvatarColor(id)}
        className={className}
        size={isMobile ? AvatarSize.small : AvatarSize.large}
      />
    ),
    [getName],
  )

  const onSetScreenShare = (enabled: boolean) => {
    uouLiveVideoShareScreenToggle(enabled ? 'on' : 'off')
    markScreenShared(enabled)
  }

  const showSoundModal = () => {
    if (isIOS() && !hasPermissions) {
      setSoundModalVisibility(true)
    }
  }

  const handleSessionEnded = (reason: number) => {
    if (reason === END_REASON_JOINED_ON_ANOTHER_DEVICE) {
      markJoinedOnAnotherDevice()
      leaveSession()
    } else {
      getSession({id: session.id, token})
    }
  }

  if (rejoining) {
    return null
  }

  return isViewer ? (
    <>
      <Video
        duplexerConfig={duplexerConfig}
        instance={instance}
        data={{Meeting: props.meeting, Attendee: props.attendee}}
        video={webinar ? false : video}
        audio={webinar ? false : audio}
        camera={selectedCamera}
        microphone={selectedMicrophone}
        speaker={selectedSpeaker}
        layout={layout}
        mobile={isMobile}
        webinar={webinar}
        knownClients={knownClients}
        recorder={recorder}
        texts={texts}
        renderAvatar={renderAvatar}
        getParticipantName={getName}
        onSetScreenShare={onSetScreenShare}
        onLoadScreenShare={markScreenShareLoading}
        onScreenShare={setScreenShared}
        onSessionEnded={handleSessionEnded}
        sidePanelOpened={sidePanelOpened}
        onJoinedSuccessfully={showSoundModal}
        desktopUsedViewportHeight={recorder ? 0 : 200}
      />
      {soundModalVisible && <JoinWithSound onClose={() => setSoundModalVisibility(false)} webinar={webinar} />}
    </>
  ) : (
    <FakeVideoComponent attendeesCount={isMobile ? 1 : 4} layout={LAYOUT.GRID} mobile={isMobile} />
  )
}
