import React from 'react'
import {Dropdown as DropdownUI, DropdownProps} from 'wix-ui-tpa'
import {classes} from './dropdown.st.css'

export const Dropdown: React.FC<DropdownProps> = ({className, label, ...rest}) => (
  <div className={className}>
    <div className={classes.label}>{label}</div>
    <DropdownUI {...rest} className={classes.root} />
  </div>
)
