import React from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {useWidgetBI} from '../../../../hooks/bi'
import {SidePanelContent} from '../../../../reducers/side-panel'
import {isCameraAndSoundSettingsOpened} from '../../../../selectors/settings'
import {ControlButton} from '../../../control-button'
import {MicNotMuted, CameraNotMuted, MicMuted, CameraMuted, SettingsNotClicked, SettingsClicked} from '../../../icons'
import {useActions, useWidgetState} from '../../../widget-state-provider'
import {DH} from '../../../../../data-hooks'
import s from './controls.scss'

export const Controls: React.FC = () => {
  const {video, audio} = useWidgetState(state => state.controls)
  const sidePanelState = useWidgetState(state => state.sidePanel)
  const settingsOpened = isCameraAndSoundSettingsOpened(sidePanelState)
  const {setAudio, setVideo, toggleSidePanel} = useActions()
  const {uouLiveVideoMicrophoneToggle, uouLiveVideoCameraToggle, uouLiveVideoSettingsToggle} = useWidgetBI()
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  const toggleAudio = () => {
    const newAudio = !audio
    setAudio(newAudio)
    uouLiveVideoMicrophoneToggle(newAudio ? 'on' : 'off')
  }

  const toggleVideo = () => {
    const newVideo = !video
    setVideo(!video)
    uouLiveVideoCameraToggle(newVideo ? 'on' : 'off')
  }

  const handleSettings = () => {
    uouLiveVideoSettingsToggle(settingsOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)
  }

  return (
    <div className={s.container}>
      <div className={s.header}>
        <ControlButton
          dataHook={DH.SETTINGS_TOGGLE}
          square
          small={isMobile}
          active={settingsOpened}
          Icon={settingsOpened ? SettingsClicked : SettingsNotClicked}
          onClick={handleSettings}
        />
      </div>
      <div className={s.footer}>
        <ControlButton
          dataHook={DH.AUDIO_TOGGLE}
          tooltipText={t(audio ? 'muteMic' : 'unmuteMic')}
          active={audio}
          Icon={audio ? MicNotMuted : MicMuted}
          onClick={toggleAudio}
        />
        <ControlButton
          dataHook={DH.VIDEO_TOGGLE}
          tooltipText={t(video ? 'stopVideo' : 'startVideo')}
          active={video}
          Icon={video ? CameraNotMuted : CameraMuted}
          onClick={toggleVideo}
        />
      </div>
    </div>
  )
}
