import React from 'react'

export const SettingsNotClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Settings Not Clicked</title>
    <g id="Icon/Settings-Not-Clicked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M13.1607289,4 L14.0451765,6.02447059 L14.7783529,6.32847059 L16.835406,5.52224075 L18.477652,7.1644867 L17.6715294,9.22070588 L17.9745882,9.95482353 L20,10.8391218 L20,13.1607289 L17.9745882,14.0451765 L17.6715294,14.7783529 L18.477652,16.8355133 L16.835406,18.4777593 L14.7783529,17.6705882 L14.0451765,17.9745882 L13.1606637,19.9989859 L10.8393363,19.9989859 L9.95388235,17.9745882 L9.22070588,17.6705882 L7.16459399,18.4777593 L5.52234803,16.8355133 L6.32752941,14.7783529 L6.02447059,14.0451765 L4,13.1607289 L4,10.8392711 L6.02447059,9.95388235 L6.32752941,9.22070588 L5.52234803,7.1644867 L7.16459399,5.52224075 L9.22070588,6.32847059 L9.95388235,6.02447059 L10.8392711,4 L13.1607289,4 Z M12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 Z"
        id="Combined-Shape"
        fill="#FFFFFF"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
)
