export enum StatusScreenType {
  SESSION_ENDED,
  LEFT_SESSION,
  BANNED,
  JOINED_ELSEWHERE,
}

export interface StatusScreenProps {
  type: StatusScreenType
}
