import React from 'react'

export const MicMuted: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Mic Muted</title>
    <g id="Icon/Mic-Muted-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M8.76923077,20 L8.76923077,19.0588235 L11.5384615,19.0588235 L11.5390808,17.1586882 C8.44073396,16.9186978 6,14.2793815 6,11.0588235 L6,10.5882353 L6.92307692,10.5882353 L6.92307692,11.0588235 C6.92307692,13.9177093 9.19609281,16.2352941 12,16.2352941 C14.8039072,16.2352941 17.0769231,13.9177093 17.0769231,11.0588235 L17.0769231,10.5882353 L18,10.5882353 L18,11.0588235 C18,14.2790608 15.5597522,16.9181721 12.4618449,17.1586164 L12.4615385,19.0588235 L15.2307692,19.0588235 L15.2307692,20 L8.76923077,20 Z M12,4 C14.2941059,4 16.1538462,5.89620577 16.1538462,8.23529412 L16.1538462,11.0588235 C16.1538462,13.3979119 14.2941059,15.2941176 12,15.2941176 C9.70589412,15.2941176 7.84615385,13.3979119 7.84615385,11.0588235 L7.84615385,8.23529412 C7.84615385,5.89620577 9.70589412,4 12,4 Z"
        id="Shape-Copy"
        fill="#FFFFFF"
        fill-rule="nonzero"
      ></path>
      <rect
        id="Rectangle"
        fill="#DF3131"
        transform="translate(12.005688, 11.493036) rotate(-323.000000) translate(-12.005688, -11.493036) "
        x="11.0605128"
        y="2.82313147"
        width="1.89035127"
        height="17.3398084"
      ></rect>
    </g>
  </svg>
)
