import React from 'react'
import {useModals} from '../../hooks/modals'
import {ModalType} from '../../interfaces'
import {UnsupportedBrowserModal} from './unsupported-browser'
import {TestVideoParticipantLimitModal} from './test-video-participant-limit'

export const Modal = () => {
  const {currentModal, hideModal} = useModals()

  const modalMap = {
    [ModalType.UNSUPPORTED_BROWSER]: UnsupportedBrowserModal,
    [ModalType.TEST_VIDEO_PARTICIPANT_LIMIT]: TestVideoParticipantLimitModal,
  }

  const CurrentModal = modalMap[currentModal]

  if (!CurrentModal) {
    return null
  }

  return <CurrentModal onClose={() => hideModal()} />
}
