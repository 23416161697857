import React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {IconButtonSkins} from 'wix-ui-tpa'
import CloseIcon from 'wix-ui-icons-common/on-stage/CloseSmall'
import classNames from 'classnames'
import {DH} from '../../../../data-hooks'
import {IconButton} from '../../common/icon-button'
import s from './side-panel-container.scss'
import {SidePanelContainerProps} from '.'

export const SidePanelContainer: React.FC<SidePanelContainerProps> = ({children, title, onClose, className}) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(s.root, className, {[s.mobile]: isMobile})}>
      <div className={s.header}>
        {title}
        <IconButton
          data-hook={DH.CLOSE_SIDE_PANEL}
          icon={<CloseIcon />}
          skin={IconButtonSkins.Full}
          onClick={() => onClose()}
        />
      </div>
      <div className={s.content}>{children}</div>
    </div>
  )
}
