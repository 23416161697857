import React from 'react'
import {isSidePanelOpened} from '../../selectors/settings'
import {AmazonVideo} from '../amazon-video'
import {SidePanel} from '../side-panel'
import {Notifications} from '../video/notifications'
import {useWidgetState} from '../widget-state-provider'
import {Controls} from './controls'
import {Header} from './header'
import s from './mobile-video.scss'

export const MobileVideo = () => {
  const sidePanelOpened = useWidgetState(state => isSidePanelOpened(state.sidePanel))

  return (
    <div className={s.container}>
      {!sidePanelOpened && <Header />}
      <div className={s.videoWithSidePanel}>
        <div className={s.video}>
          <AmazonVideo />
        </div>
        <SidePanel />
      </div>
      <Notifications mobile />
      {!sidePanelOpened && <Controls />}
    </div>
  )
}
