import React from 'react'
import s from './style.scss'

export const Clock: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon/Waiting-Screen-Clock" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 Z M15,13 L15,12 L12.5,12 L12.5,7 L11.5,7 L11.5,13 L15,13 Z"
        className={s.waitingRoomStatusColor}
      />
      <rect
        className={s.waitingRoomStatusColor}
        transform="translate(6.500000, 6.000000) rotate(45.000000) translate(-6.500000, -6.000000) "
        x="6"
        y="4"
        width="1"
        height="4"
      />
      <rect
        className={s.waitingRoomStatusColor}
        transform="translate(17.500000, 6.000000) scale(-1, 1) rotate(45.000000) translate(-17.500000, -6.000000) "
        x="17"
        y="4"
        width="1"
        height="4"
      />
    </g>
  </svg>
)
