import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {useWidgetBI} from '../../hooks/bi'
import {isWebinar} from '../../selectors/session'
import {isSidePanelOpened} from '../../selectors/settings'
import {useMediaDevicesContext} from '../media-devices-provider/media-devices-provider'
import {SidePanel} from '../side-panel'
import {useWidgetState} from '../widget-state-provider'
import {useAndroidGpuVideoUnblockHack} from '../../../../../hooks/android-gpu-video-hack'
import {Preview} from './preview'
import {WaitingForm} from './waiting-form'
import s from './mobile-waiting-room.scss'
import {ErrorPopupWrapped} from './waiting-room'

export const MobileWaitingRoom = () => {
  const webinar = useWidgetState(isWebinar)
  const sidePanelOpened = useWidgetState(state => isSidePanelOpened(state.sidePanel))
  const {uouLiveVideoWaitingScreenLoaded} = useWidgetBI()
  const {IOSBrowserNotSupported} = useMediaDevicesContext()
  const {t} = useTranslation()

  useAndroidGpuVideoUnblockHack()

  useEffect(() => {
    uouLiveVideoWaitingScreenLoaded()
  }, [])

  if (IOSBrowserNotSupported) {
    return (
      <ErrorPopupWrapped
        title={t('notSafariOnIOSPopupTitle')}
        lineOne={t('notSafariOnIOSPopupLineOne')}
        hideRefresh={true}
      />
    )
  }

  return (
    <div className={classNames(s.container, {[s.withSidePanel]: sidePanelOpened})}>
      <div className={s.content}>
        {!webinar && <Preview />}
        <WaitingForm />
      </div>
      <SidePanel className={s.sidePanel} />
    </div>
  )
}
