import {useBi, useEnvironment} from '@wix/yoshi-flow-editor'
import {SessionType} from '@wix/ambassador-livevideo-server/types'
import {
  uouLiveVideoWaittingScreenLoaded,
  uouLiveVideoJoinClick,
  uouLiveVideoMicrophoneToggle,
  uouLiveVideoChatMessagePublish,
  uouLiveVideoLeaveClick,
  uouLiveVideoGoToSiteClick,
  uouLiveVideoExitScreenLoaded,
  uouLiveVideoReactionPublish,
  uouLiveVideoChatScreenToggle,
  uouLiveVideoParticipantsListToggle,
  uouLiveVideoCameraToggle,
  uouLiveVideoShareScreenToggle,
  uouLiveVideoSettingsToggle,
  uouLiveVideoChangeLayout,
  uouLiveVideoRejoinClick,
} from '@wix/bi-logger-live-video-uou/v2'
import {useWidgetState} from '../components/widget-state-provider'
import {isSessionConnected} from '../selectors/session'

export const useWidgetBI = () => {
  const bi = useBi()
  const {session, participant} = useWidgetState(state => state.session)

  const {isViewer} = useEnvironment()

  const {visitorId, metaSiteId} = useWidgetState(state => state.environment)
  const meeting_id = session.id
  const video_conferencing_type =
    (session.sessionType && (session.sessionType === SessionType.MEETING ? 'meeting' : 'stream')) || null
  const visitor_id = visitorId
  const {appDefId, entityId, entityType, instanceId} = session.entityReference || {}
  const origin = useWidgetState(isSessionConnected) ? 'meeting' : 'waiting_room'
  const participant_id = participant?.participantId ?? null

  const getCommonParams = () => ({
    app_id: appDefId,
    app_instance_id: instanceId,
    entity_id: entityId,
    entity_name: entityType,
    meeting_id,
    video_conferencing_type,
    visitor_id,
    is_preview: !isViewer,
    _msid: metaSiteId,
  })

  return {
    uouLiveVideoWaitingScreenLoaded: () =>
      bi.report(uouLiveVideoWaittingScreenLoaded({...getCommonParams(), participant_id})),
    uouLiveVideoJoinClick: () => bi.report(uouLiveVideoJoinClick({...getCommonParams(), participant_id})),
    uouLiveVideoMicrophoneToggle: (choice: Choice) =>
      bi.report(uouLiveVideoMicrophoneToggle({...getCommonParams(), choice, origin})),
    uouLiveVideoCameraToggle: (choice: Choice) =>
      bi.report(uouLiveVideoCameraToggle({...getCommonParams(), choice, origin})),
    uouLiveVideoShareScreenToggle: (choice: Choice) =>
      bi.report(uouLiveVideoShareScreenToggle({...getCommonParams(), choice})),
    uouLiveVideoChangeLayout: (layout_type: 'speaker' | 'grid') =>
      bi.report(uouLiveVideoChangeLayout({...getCommonParams(), layout_type})),
    uouLiveVideoSettingsToggle: (choice: Choice) =>
      bi.report(uouLiveVideoSettingsToggle({...getCommonParams(), choice})),
    uouLiveVideoParticipantsListToggle: (choice: Choice) =>
      bi.report(uouLiveVideoParticipantsListToggle({...getCommonParams(), choice})),
    uouLiveVideoChatScreenToggle: (choice: Choice) =>
      bi.report(uouLiveVideoChatScreenToggle({...getCommonParams(), choice})),
    uouLiveVideoChatMessagePublish: () => bi.report(uouLiveVideoChatMessagePublish(getCommonParams())),
    uouLiveVideoReactionPublish: () => bi.report(uouLiveVideoReactionPublish(getCommonParams())),
    uouLiveVideoLeaveClick: () => bi.report(uouLiveVideoLeaveClick({...getCommonParams(), participant_id})),
    uouLiveVideoExitScreenLoaded: (reason: UouLiveVideoExitScreenLoadedReason) =>
      bi.report(uouLiveVideoExitScreenLoaded({...getCommonParams(), participant_id, reason})),
    uouLiveVideoGoToSiteClick: () => bi.report(uouLiveVideoGoToSiteClick({...getCommonParams()})),
    uouLiveVideoRejoinClick: () => bi.report(uouLiveVideoRejoinClick({...getCommonParams(), participant_id})),
  }
}

export type UouLiveVideoExitScreenLoadedReason = 'end' | 'leave' | 'banned' | 'joined_elsewhere'
type Choice = 'on' | 'off'
