import {useContext} from 'react'
import {WidgetStateContext} from '../components/widget-state-provider/widget-state-provider'
import {isParticipantVideoStreamer} from '../selectors/session'

export const useStreamer = () => {
  const state = useContext(WidgetStateContext)

  return {
    streamer: isParticipantVideoStreamer(state),
  }
}
