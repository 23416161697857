import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button} from '../common/button'
import s from './link-expired.scss'
import {LinkExpiredProps} from '.'

export const LinkExpired: React.FC<LinkExpiredProps> = ({navigateToMainPage}) => {
  const {t} = useTranslation()
  return (
    <div className={s.container}>
      <div className={s.content}>
        <h1 className={s.text}>{t('linkExpired.text')}</h1>
        <Button className={s.button} onClick={() => navigateToMainPage()}>
          {t('linkExpired.button')}
        </Button>
      </div>
    </div>
  )
}
