import React from 'react'

export const EnterFullScreen: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(4.000000, 3.500000)" fill="currentColor">
        <rect x="0" y="0.5" width="1" height="5" />
        <rect
          transform="translate(3.500000, 1.000000) rotate(90.000000) translate(-3.500000, -1.000000) "
          x="3"
          y="-1.5"
          width="1"
          height="5"
        />
      </g>
      <g
        transform="translate(12.000000, 17.500000) scale(1, -1) translate(-12.000000, -17.500000) translate(4.000000, 14.500000)"
        fill="currentColor"
      >
        <g>
          <rect x="0" y="0.5" width="1" height="5" />
          <rect
            transform="translate(3.500000, 1.000000) rotate(90.000000) translate(-3.500000, -1.000000) "
            x="3"
            y="-1.5"
            width="1"
            height="5"
          />
        </g>
        <g transform="translate(13.000000, 3.000000) scale(-1, 1) translate(-13.000000, -3.000000) translate(10.000000, 0.000000)">
          <rect x="0" y="0.5" width="1" height="5" />
          <rect
            transform="translate(3.500000, 1.000000) rotate(90.000000) translate(-3.500000, -1.000000) "
            x="3"
            y="-1.5"
            width="1"
            height="5"
          />
        </g>
      </g>
      <g
        transform="translate(17.000000, 6.500000) scale(-1, 1) translate(-17.000000, -6.500000) translate(14.000000, 3.500000)"
        fill="currentColor"
      >
        <rect x="0" y="0.5" width="1" height="5" />
        <rect
          transform="translate(3.500000, 1.000000) rotate(90.000000) translate(-3.500000, -1.000000) "
          x="3"
          y="-1.5"
          width="1"
          height="5"
        />
      </g>
    </g>
  </svg>
)
