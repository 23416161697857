import {unblockScreenShare, useSignalingApi} from '@wix/live-video-components'
import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DH} from '../../../data-hooks'
import {useWidgetBI} from '../../hooks/bi'
import {SidePanelContent} from '../../reducers/side-panel'
import {isWebinar} from '../../selectors/session'
import {isChatMessagesPending, isChatOpened} from '../../selectors/settings'
import {Tooltip} from '../common/tooltip'
import {ControlButton} from '../control-button'
import {
  CameraMuted,
  CameraNotMuted,
  ChatClicked,
  ChatNotClicked,
  MicMuted,
  MicNotMuted,
  ReactionsClicked as ReactionsButtonClicked,
  ReactionsNotClicked as ReactionsButtonNotClicked,
  ShareClicked,
  ShareNotClicked,
} from '../icons'
import {useMediaDevicesContext} from '../media-devices-provider/media-devices-provider'
import {useActions, useWidgetState} from '../widget-state-provider'
import s from './controls.scss'
import {Reactions} from './reactions'
import {ControlsProps} from '.'

export const Controls: React.FC<ControlsProps> = () => {
  const [reactionsOpened, setReactionsOpened] = React.useState(false)
  const [reactionsIconActive, setReactionsIconActive] = React.useState(false)

  const webinar = useWidgetState(isWebinar)
  const {audio, video, screenShared, screenShareLoading} = useWidgetState(state => state.controls)
  const {sidePanel: sidePanelState} = useWidgetState(state => state)
  const {toggleScreenshare} = useSignalingApi()
  const {devices} = useMediaDevicesContext()

  const {setVideo, setAudio, toggleSidePanel} = useActions()
  const {
    uouLiveVideoMicrophoneToggle,
    uouLiveVideoCameraToggle,
    uouLiveVideoChatScreenToggle,
    uouLiveVideoShareScreenToggle,
  } = useWidgetBI()

  const chatOpened = isChatOpened(sidePanelState)
  const {t} = useTranslation()

  const onVideoClicked = () => {
    if (devices.cameras.length) {
      const newVideo = !video
      setVideo(newVideo)
      uouLiveVideoCameraToggle(newVideo ? 'on' : 'off')
    } else {
      toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)
    }
  }

  const onMicClicked = () => {
    if (devices.microphones.length) {
      const newAudio = !audio
      setAudio(newAudio)
      uouLiveVideoMicrophoneToggle(newAudio ? 'on' : 'off')
    } else {
      toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)
    }
  }

  const onScreenShareClicked = () => {
    const enableScreenShare = !screenShared

    if (enableScreenShare) {
      if (screenShareLoading) {
        // If we get here, Amazon SDK broke - let's work it around
        unblockScreenShare()
        toggleScreenshare(false)
      }
      // Set timeout is necessary for the above hack to work
      setTimeout(() => {
        toggleScreenshare(true)
      })
    } else {
      toggleScreenshare(false)
    }

    uouLiveVideoShareScreenToggle(enableScreenShare ? 'on' : 'off')
  }

  const onChatClicked = () => {
    uouLiveVideoChatScreenToggle(chatOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.CHAT)
  }

  return (
    <div className={s.container}>
      {!webinar && (
        <>
          <ControlButton
            dataHook={DH.AUDIO_TOGGLE}
            tooltipText={t(audio ? 'muteMic' : 'unmuteMic')}
            onClick={onMicClicked}
            Icon={audio ? MicNotMuted : MicMuted}
            active={audio}
          />
          <ControlButton
            dataHook={DH.VIDEO_TOGGLE}
            tooltipText={t(video ? 'stopVideo' : 'startVideo')}
            onClick={onVideoClicked}
            Icon={video ? CameraNotMuted : CameraMuted}
            active={video}
          />
          <ControlButton
            dataHook={DH.SCREEN_SHARE_TOGGLE}
            tooltipText={t(screenShared ? 'stopSharing' : 'shareScreen')}
            onClick={onScreenShareClicked}
            Icon={screenShared ? ShareClicked : ShareNotClicked}
            active={screenShared}
          />
        </>
      )}
      {webinar ? (
        <ControlButton>
          <Reactions />
        </ControlButton>
      ) : (
        <Tooltip
          dataHook={DH.REACTIONS_TOGGLE}
          onVisibilityChange={() => {
            setReactionsOpened(!reactionsOpened)
            setReactionsIconActive(!reactionsIconActive)
          }}
          placement="top"
          trigger="click"
          tooltip={<Reactions />}
          className={s.tooltipContainer}
        >
          <ControlButton
            tooltipDisabled={reactionsOpened && !reactionsIconActive}
            tooltipText={t('reactions')}
            active={reactionsIconActive && reactionsOpened}
            Icon={reactionsIconActive ? ReactionsButtonClicked : ReactionsButtonNotClicked}
          />
        </Tooltip>
      )}
      <ControlButton
        dataHook={DH.CHAT_TOGGLE}
        tooltipText={t('chat')}
        onClick={onChatClicked}
        Icon={chatOpened ? ChatClicked : ChatNotClicked}
        active={chatOpened}
        pimple={isChatMessagesPending(sidePanelState)}
      />
    </div>
  )
}
