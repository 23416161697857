import * as React from 'react'
import classNames from 'classnames'
import s from './recording.scss'

export interface RecordingProps {
  animated?: boolean
}

export const Recording: React.FC<RecordingProps> = ({animated = false}) => {
  return <div className={classNames(s.circle, {[s.animated]: animated})}></div>
}
