import React from 'react'

export const ParticipantsMicMuted: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Icon/Mic-Muted-Participant-List" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M18,10.5882353 L18,11.0588235 C18,14.2790608 15.5597522,16.9181721 12.4618449,17.1586164 L12.4615385,19.0588235 L15.2307692,19.0588235 L15.2307692,20 L8.76923077,20 L8.76923077,19.0588235 L11.5384615,19.0588235 L11.5390808,17.1586882 C10.766247,17.0988264 10.0343282,16.8896846 9.37099689,16.5594781 L9.94696572,15.7945826 C10.5747064,16.0779125 11.2693274,16.2352941 12,16.2352941 C14.8039072,16.2352941 17.0769231,13.9177093 17.0769231,11.0588235 L17.0769231,10.5882353 L18,10.5882353 Z M6.92307692,10.5882353 L6.92307692,11.0588235 C6.92307692,12.4826667 7.48688748,13.7722429 8.39929223,14.7081157 L7.832752,15.4601901 C6.70274553,14.3476787 6,12.7869278 6,11.0588235 L6,10.5882353 L6.92307692,10.5882353 Z M16.1538462,8.23529412 L16.1538462,11.0588235 C16.1538462,13.3979119 14.2941059,15.2941176 12,15.2941176 C11.4823905,15.2941176 10.9868932,15.1975875 10.5298799,15.0212199 L16.1099528,7.61736712 C16.1388717,7.81907672 16.1538462,8.0254024 16.1538462,8.23529412 Z M12,4 C13.3203915,4 14.4968919,4.62815088 15.2577341,5.60735683 L8.96795426,13.9537292 C8.27218103,13.196446 7.84615385,12.1783623 7.84615385,11.0588235 L7.84615385,8.23529412 C7.84615385,5.89620577 9.70589412,4 12,4 Z"
        id="Combined-Shape"
        fill="#000000"
        fill-rule="nonzero"
      ></path>
      <rect
        id="Rectangle"
        fill="#000000"
        transform="translate(11.316996, 11.225001) rotate(-323.000000) translate(-11.316996, -11.225001) "
        x="10.8169964"
        y="2.55509667"
        width="1"
        height="17.3398084"
      ></rect>
    </g>
  </svg>
)
