import {useContext} from 'react'
import {WidgetStateContext} from '../components/widget-state-provider/widget-state-provider'
import {isParticipantVideoRecorder} from '../selectors/session'

export const useRecorder = () => {
  const state = useContext(WidgetStateContext)

  return {
    recorder: isParticipantVideoRecorder(state),
    recorderJoined: state.recorder.recording,
  }
}
