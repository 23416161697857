import React from 'react'

export const SpeakerViewPopover: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Speaker View Popover</title>
    <g id="Icon/Speaker-View-Popover" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(4.000000, 4.000000)" stroke="#000000">
        <rect id="Rectangle-Copy-2" x="0.5" y="0.5" width="7" height="15"></rect>
        <rect id="Rectangle-Copy" x="10.5" y="0.5" width="5" height="3"></rect>
        <rect id="Rectangle-Copy-3" x="10.5" y="6.5" width="5" height="3"></rect>
        <rect id="Rectangle-Copy-4" x="10.5" y="12.5" width="5" height="3"></rect>
      </g>
    </g>
  </svg>
)
