import React from 'react'
import {AvatarSize} from 'wix-ui-tpa'
import {Avatar} from '../../../common/avatar'
import {ParticipantsMicMuted, MicNotMuted, CameraNotMuted, ParticipantsCameraMuted} from '../../../icons'
import {getReactionCode} from '../../../../selectors/reactions'
import {DH} from '../../../../../data-hooks'
import s from './participant.scss'
import {ParticipantProps} from '.'

export const Participant: React.FC<ParticipantProps> = ({
  name,
  label,
  reaction,
  colorId,
  muted,
  video,
  screenshare,
  webinar,
}) => (
  <li className={s.container} data-hook={DH.PARTICIPANT}>
    <div className={s.participantInfo}>
      <Avatar colorId={colorId} name={name} size={AvatarSize.small} />
      <div className={s.nameContainer}>
        <span className={s.name} data-hook={DH.PARTICIPANT_NAME}>
          {name}
        </span>
        {label && (
          <span className={s.label} data-hook={DH.PARTICIPANT_LABEL}>
            {label}
          </span>
        )}
      </div>
    </div>
    <div className={s.icons}>
      {reaction && <div className={s.reaction}>{getReactionCode(reaction)}</div>}
      {!webinar && <div className={s.mic}>{muted ? <ParticipantsMicMuted /> : <MicNotMuted />}</div>}
      {!webinar && (video || screenshare ? <CameraNotMuted /> : <ParticipantsCameraMuted />)}
    </div>
  </li>
)
