import {REACTIONS} from '@wix/live-video-commons'
import React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {DH} from '../../../../data-hooks'
import {isAndroid} from '../../../selectors/environment'
import {useWidgetState} from '../../widget-state-provider'
import {isMeeting} from '../../../selectors/session'
import s from './reactions.scss'
import {Reaction} from './reaction'
import {ReactionsProps} from '.'

export const Reactions: React.FC<ReactionsProps> = ({onReactionClick}) => {
  const {isMobile} = useEnvironment()
  const meeting = useWidgetState(isMeeting)
  return (
    <div
      className={classNames(s.reactions, {[s.mobile]: isMobile, [s.android]: isAndroid(), [s.meeting]: meeting})}
      data-hook={DH.REACTIONS_CONTAINER}
    >
      {REACTIONS.map(({id, code}) => (
        <Reaction key={id} id={id} code={code} onClick={onReactionClick} />
      ))}
    </div>
  )
}
