import classNames from 'classnames'
import React, {CSSProperties, useEffect, useState} from 'react'
import {useSettings} from '@wix/tpa-settings/react'
import screenfull from 'screenfull'
import Div100vh from 'react-div-100vh'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import settingsParams, {WixImage} from '../../../settingsParams'
import {getImageStyle} from '../../../utils/image'
import {isWaitingScreen} from '../../selectors/session'
import {useActions, useWidgetState} from '../widget-state-provider'
import s from './container.scss'
import {ContainerProps} from './index'

export const Container: React.FC<ContainerProps> = ({id, children, forcedScreen}) => {
  const container = React.useRef<HTMLDivElement>()
  const settings = useSettings()
  const {setFullScreen} = useActions()
  const waitingScreenDifferentBackgroundEnabled = settings.get(settingsParams.waitingScreenDifferentBackground)
  const waitingScreen = useWidgetState(isWaitingScreen) && !['live-video-screen', 'end-screen'].includes(forcedScreen)
  const isWaitingScreenBackgroundApplied = waitingScreenDifferentBackgroundEnabled && waitingScreen
  const [style, setStyle] = useState<CSSProperties>({})
  const backgroundImage = settings.get(
    isWaitingScreenBackgroundApplied ? settingsParams.waitingScreenBackgroundImage : settingsParams.backgroundImage,
  ) as WixImage
  const backgroundImageOpacity = settings.get(
    isWaitingScreenBackgroundApplied
      ? settingsParams.waitingScreenBackgroundImageOpacity
      : settingsParams.backgroundImageOpacity,
  )
  const {isSSR, isMobile} = useEnvironment()
  const [landscape, setLandscape] = useState(isSSR ? false : window.innerHeight < window.innerWidth)

  const onFullScreenChange = () => {
    if (screenfull.isEnabled && !screenfull.isFullscreen) {
      setFullScreen(false)
    }
  }

  useEffect(() => {
    if (!isSSR) {
      container.current.scrollIntoView?.()
    }
  }, [isSSR])

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', onFullScreenChange)
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', onFullScreenChange)
      }
    }
  }, [])

  useEffect(() => {
    if (backgroundImage.relativeUri) {
      const {clientWidth, clientHeight} = container.current || {}
      setStyle(
        getImageStyle({
          image: {id: backgroundImage.relativeUri, height: backgroundImage.height, width: backgroundImage.width},
          container: {
            width: clientWidth,
            height: clientHeight,
          },
          opacity: backgroundImageOpacity as number,
        }),
      )
    } else {
      setStyle(null)
    }
  }, [backgroundImage.relativeUri, backgroundImageOpacity])

  const resizeHandler = () => {
    if (window.innerHeight < window.innerWidth) {
      setLandscape(true)
    } else {
      setLandscape(false)
    }
  }

  useEffect(() => {
    if (!isSSR && isMobile) {
      window.addEventListener('resize', resizeHandler)
    }

    return () => window.removeEventListener('resize', resizeHandler)
  }, [isSSR, isMobile])

  const ContainerElement = landscape && waitingScreen && isMobile ? 'div' : Div100vh

  return (
    <ContainerElement>
      <div className={s.mainContainer} ref={container}>
        <div className={s.image} style={style} />
        <div
          className={classNames(s.color, {
            [s.waitingScreenColor]: isWaitingScreenBackgroundApplied,
          })}
        />
        <div className={classNames(id, s.container)}>{children}</div>
      </div>
    </ContainerElement>
  )
}
