import React from 'react'

export const ShareNotClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Share Not Clicked </title>
    <g id="Icon/Share-Not-Clicked-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M20.0003199,16.0000904 L20.0003199,5 L3.99516296,5 L3.99516296,16.0000904 L7.95251412,16.0000904 L7.95251412,17.9230769 L6.54626412,17.9230769 L6.54626412,19 L7.95251412,19 L16.0384516,19 L17.4447016,19 L17.4447016,17.9230769 L16.0384516,17.9230769 L16.0384516,16.0000904 L20.0003199,16.0000904 Z M14.9837641,17.9230769 L9.00720162,17.9230769 L9.00720162,15.9989929 L14.9837641,15.9989929 L14.9837641,17.9230769 Z"
        id="Shape"
        fill="#FFFFFF"
        fill-rule="nonzero"
        transform="translate(11.997741, 12.000000) scale(-1, 1) translate(-11.997741, -12.000000) "
      ></path>
    </g>
  </svg>
)
