import React from 'react'
import type {Actions} from '../../interfaces'
import type {State} from '../../reducers'

export const WidgetStateContext = React.createContext<State>(null)

export function useWidgetState<T>(selector: (state: State) => T): T {
  return selector(React.useContext<State>(WidgetStateContext))
}

export const WidgetActionsContext = React.createContext<Actions>(null)

export const useActions = () => React.useContext<Actions>(WidgetActionsContext)

export const WidgetAuthorizationContext = React.createContext<string>(null)

export const useAuthorization = () => React.useContext<string>(WidgetAuthorizationContext)
