import {ArrowDownSmall} from 'wix-ui-icons-common'
import React from 'react'
import {ButtonPriority as PRIORITY, ButtonSize as SIZE} from 'wix-ui-tpa'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button} from '../../../common/button'
import s from './scroll-button.scss'

interface ScrollButtonProps {
  onClick: () => void
}

export const ScrollButton: React.FC<ScrollButtonProps> = ({onClick}) => {
  const {t} = useTranslation()
  return (
    <Button
      className={s.root}
      onClick={onClick}
      priority={PRIORITY.secondary}
      size={SIZE.tiny}
      suffixIcon={<ArrowDownSmall />}
    >
      {t('chat.scrollToNew')}
    </Button>
  )
}
