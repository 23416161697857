import React from 'react'
import {IconButtonSkins} from 'wix-ui-tpa'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DH} from '../../../../../data-hooks'
import {TextField} from '../../../common/text-field'
import {IconButton} from '../../../common/icon-button'
import {Send} from '../../../icons/send'
import s from './input.scss'
import {handleKeyDown} from './utils'

interface InputProps {
  value: string
  onSubmit: () => void
  onChange: (value: string) => void
}

export const Input: React.FC<InputProps> = ({onChange, onSubmit, value}) => {
  const {t} = useTranslation()
  const submit = handleKeyDown('Enter', onSubmit)

  return (
    <TextField
      data-hook={DH.CHAT_INPUT}
      onKeyPress={submit}
      className={s.root}
      placeholder={t('chat.input.placeholder')}
      value={value}
      maxLength={500}
      suffix={
        <IconButton
          className={s.suffix}
          disabled={!value}
          icon={<Send className={s.suffixIcon} />}
          skin={IconButtonSkins.Full}
          onClick={onSubmit}
        />
      }
      onChange={event => onChange(event.target.value)}
    />
  )
}
