import React from 'react'

export const GridClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Grid Clicked</title>
    <g id="Icon/Grid-Clicked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(4.000000, 4.000000)" fill="#000000">
        <rect id="Rectangle" x="0" y="0" width="7" height="7"></rect>
        <rect id="Rectangle-Copy-2" x="0" y="9" width="7" height="7"></rect>
        <rect id="Rectangle-Copy" x="9" y="0" width="7" height="7"></rect>
        <rect id="Rectangle-Copy-3" x="9" y="9" width="7" height="7"></rect>
      </g>
    </g>
  </svg>
)
