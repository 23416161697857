import {SidePanelContent, SidePanelState} from '../reducers/side-panel'

export const isSidePanelOpened = (state: SidePanelState) => state.open

export const isNameSettingsOpened = (state: SidePanelState) => state.content === SidePanelContent.NAME

export const isParticipantsOpened = (state: SidePanelState) => state.content === SidePanelContent.PARTICIPANTS

export const isBackgroundSettingsOpened = (state: SidePanelState) => state.content === SidePanelContent.BACKGROUND

export const isCameraAndSoundSettingsOpened = (state: SidePanelState) =>
  state.content === SidePanelContent.CAMERA_AND_SOUND

export const isChatOpened = (state: SidePanelState) => state.content === SidePanelContent.CHAT

export const isMobileSettingsOpened = (state: SidePanelState) => state.content === SidePanelContent.MOBILE_SETTINGS

export const isChatMessagesPending = (state: SidePanelState) => state.chatMessagesPending
