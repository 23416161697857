import React from 'react'
import {Avatar as TPAAvatar, AvatarSize} from 'wix-ui-tpa'
import classNames from 'classnames'
import {classes} from './avatar.st.css'
import {AvatarProps} from '.'

const colors = [
  classes.colorOne,
  classes.colorTwo,
  classes.colorThree,
  classes.colorFour,
  classes.colorFive,
  classes.colorSix,
]

export const Avatar: React.FC<AvatarProps> = ({name, colorId, className, size = AvatarSize.small}) => (
  <TPAAvatar className={classNames(classes.root, colors[colorId], className)} name={name} size={size} />
)
