import React from 'react'

export const ReactionsNotClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Reactions Not Clicked</title>
    <g id="Icon/Reactions-Not-Clicked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M3.91147018,12.4148552 C3.01246933,10.7060376 2.71246905,8.78281773 3.31146961,7.18066789 C3.81147008,5.79185358 4.70947093,4.72410924 6.00847215,4.2963715 C8.30547432,3.44196269 10.7014766,4.72410924 12.0004778,6.53959461 C13.3984791,4.50970703 15.9944816,3.44196269 18.0914835,4.2963715 C19.2904847,4.830777 20.2884856,5.79185358 20.688486,7.07400012 C21.2874866,8.67614997 20.9874863,10.5993698 20.0894854,12.4148552 C18.2914837,15.9402248 12.0994779,20 12.0004778,20 C11.9004777,20 5.70847187,15.7268893 3.91147018,12.4148552 Z"
        id="Love-Selected-Copy-2"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
)
