import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {useMediaDevicesContext} from '../../media-devices-provider/media-devices-provider'
import {useWidgetState} from '../../widget-state-provider'
import {Dropdown} from '../../common/dropdown'
import {isWebinar} from '../../../selectors/session'
import s from './camera-and-sound.scss'

export const CameraAndSound = () => {
  const {t} = useTranslation()
  const webinar = useWidgetState(isWebinar)
  const {
    devices: {cameras, microphones, speakers},
    selectedMicrophone,
    selectedSpeaker,
    selectedCamera,
    setCamera,
    setMicrophone,
    setSpeaker,
  } = useMediaDevicesContext()

  const hasCameras = Boolean(cameras?.length)
  const hasMicrophones = Boolean(microphones?.length)
  const hasSpeakers = Boolean(speakers?.length)
  const hasDevices = hasCameras || hasMicrophones

  const getEmptyOption = (value: string) => [{id: null, value}]

  return (
    <div className={s.root}>
      {!hasDevices && !webinar ? (
        <div className={s.noDevices}>
          <span>{t('sidePanel.cameraSound.allowAccess')}</span>
          <a
            className={s.link}
            href="https://support.wix.com/en/article/providing-camera-and-microphone-access-for-wix-live-events"
            target="_blank"
            rel="noreferrer"
          >
            {` ${t('sidePanel.cameraSound.allowAccessLearnHow')}`}
          </a>
        </div>
      ) : null}
      {webinar && <div className={s.webinarMessage}>{t('sidePanel.cameraSound.webinarNotRequiredDevices')}</div>}
      {!webinar && (
        <>
          <Dropdown
            className={s.control}
            disabled={!hasCameras}
            label={t('settings.camera')}
            options={hasCameras ? cameras : getEmptyOption(t('sidePanel.cameraSound.noCamera'))}
            initialSelectedId={selectedCamera}
            onChange={({id}) => setCamera(id)}
          />
          <Dropdown
            className={s.control}
            label={t('settings.microphone')}
            disabled={!hasMicrophones}
            options={hasMicrophones ? microphones : getEmptyOption(t('sidePanel.cameraSound.noMicrophone'))}
            initialSelectedId={selectedMicrophone}
            onChange={({id}) => setMicrophone(id)}
          />
          <Dropdown
            className={s.control}
            label={t('settings.speakers')}
            disabled={!hasSpeakers}
            options={hasSpeakers ? speakers : getEmptyOption(t('sidePanel.cameraSound.noSpeaker'))}
            initialSelectedId={selectedSpeaker}
            onChange={({id}) => setSpeaker(id)}
          />
        </>
      )}
    </div>
  )
}
