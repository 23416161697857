import React from 'react'

export const ChatNotClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>09 Icons / General / CommentFilled</title>
    <g id="09-Icons-/-General-/-CommentFilled" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polygon
        id="Rectangle-Copy"
        fill="#FFFFFF"
        fill-rule="nonzero"
        points="4 5 20 5 20 17.0634766 14.0131836 17.0634766 8.03930664 21 8.03930664 17.0634766 4 17.0634766"
      ></polygon>
    </g>
  </svg>
)
