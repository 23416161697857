import React from 'react'

export const ParticipantsCameraMuted: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Icon/Muted-Camera-Participant-List" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M14.962,5.99912141 L5.919,17.9991214 L3,18 L3,6 L14.962,5.99912141 Z M15,9.08912141 L15,18 L8.285,17.9991214 L15,9.08912141 Z M21,8 L21,16 L17,14 L17,10 L21,8 Z"
        id="Combined-Shape"
        fill="#000000"
      ></path>
      <rect
        id="Rectangle"
        fill="#000000"
        transform="translate(10.516525, 12.287263) rotate(-323.000000) translate(-10.516525, -12.287263) "
        x="10.0165247"
        y="2.28726261"
        width="1"
        height="20"
      ></rect>
    </g>
  </svg>
)
