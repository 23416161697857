import React from 'react'

export const SpeakerViewNotClicked: React.FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Icon/Speaker View Not Clicked</title>
    <g id="Icon/Speaker-View-Not-Clicked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
        <rect id="Rectangle-Copy-2" x="0" y="0" width="8" height="16"></rect>
        <rect id="Rectangle-Copy" x="10" y="0" width="6" height="4"></rect>
        <rect id="Rectangle-Copy-3" x="10" y="6" width="6" height="4"></rect>
        <rect id="Rectangle-Copy-4" x="10" y="12" width="6" height="4"></rect>
      </g>
    </g>
  </svg>
)
