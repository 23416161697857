import React from 'react'
import {Text, TextField} from 'wix-ui-tpa'
import s from './name.scss'

export const Name = () => (
  <>
    <Text>Name</Text>
    <TextField className={s.input} />
  </>
)
