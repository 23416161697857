import imageSDK from 'image-client-api/dist/imageClientSDK'

enum IMAGE_POSITION {
  CENTER_CENTER = 5,
}

export const getImageStyle = ({
  image,
  container: {width, height},
  opacity = 100,
  position = IMAGE_POSITION.CENTER_CENTER,
  backgroundSize = 'cover',
}: GetImageStyleArgs) => {
  const isCropped = image.height && height ? image.width / image.height !== width / height : false
  return {
    backgroundImage: `url(${imageSDK.getScaleToFillImageURL(image.id, image.width, image.height, width, height, {
      quality: 90,
      focalPoint: isCropped ? resolveFocalPoint(position) : null,
      upscaleMethod: 'auto',
    })})`,
    backgroundPosition: resolveImagePosition(position),
    backgroundRepeat: 'no-repeat',
    backgroundSize,
    opacity: opacity / 100,
  }
}

const resolveFocalPoint = (position: IMAGE_POSITION) => {
  const focalPoint = {x: 50, y: 50}

  if (position % 3 === 0) {
    focalPoint.x = 100
  } else if ((position - 1) % 3 === 0) {
    focalPoint.x = 0
  }

  if (position < 4) {
    focalPoint.y = 0
  } else if (position >= 7) {
    focalPoint.y = 100
  }
  return focalPoint
}

const resolveImagePosition = (position: IMAGE_POSITION) => {
  let horizontal = 'center',
    vertical = ''

  if (position % 3 === 0) {
    horizontal = 'right'
  } else if ((position - 1) % 3 === 0) {
    horizontal = 'left'
  }

  if (position < 4) {
    vertical = 'top'
  } else if (position < 7) {
    vertical = 'center'
  } else {
    vertical = 'bottom'
  }

  return `${horizontal} ${vertical}`
}

interface Container {
  width: number
  height: number
}

interface GetImageStyleArgs {
  image: {
    id: string
    width: number
    height: number
  }
  container: Container
  opacity?: number
  position?: IMAGE_POSITION
  backgroundSize?: 'cover' | 'contain'
  superUpscaled?: boolean
}
