import {useEffect} from 'react'

// Hack for Android Video GPU bug (red exclamation mark with warning instead of video)
// No browser detection to reduce complexity and detection-related bugs.
// This is not resource-hungry.
export const useAndroidGpuVideoUnblockHack = () => {
  useEffect(() => {
    document.createElement('video').srcObject = new MediaStream()
  }, [])
}
