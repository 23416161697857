import React, {useState} from 'react'
import {ChevronDown, ChevronUp, Exit} from 'wix-ui-icons-common'
import {LAYOUT} from '@wix/live-video-commons'
import {ActionsMenuLayout} from 'wix-ui-tpa'
import {useTranslation} from '@wix/yoshi-flow-editor'
import screenfull from 'screenfull'
import classNames from 'classnames'
import {SidePanelContent} from '../../../reducers/side-panel'
import {ControlButton} from '../../control-button'
import {useActions, useWidgetState} from '../../widget-state-provider'
import {getActiveParticipantCount} from '../../../selectors/participants'
import {isWebinar} from '../../../selectors/session'
import {isCameraAndSoundSettingsOpened, isParticipantsOpened} from '../../../selectors/settings'
import {
  ExitFullScreen,
  EnterFullScreen,
  SettingsNotClicked,
  SettingsClicked,
  ViewersClicked,
  ViewersNotClicked,
  GridPopover,
  SpeakerViewPopover,
  GridClicked,
  SpeakerViewClicked,
  SpeakerViewNotClicked,
  GridNotClicked,
} from '../../icons'
import {Tooltip} from '../../common/tooltip'
import {useWidgetBI} from '../../../hooks/bi'
import {DH} from '../../../../data-hooks'
import {useRecorder} from '../../../hooks/recorder'
import {Recording} from '../../icons/recording/recording'
import s from './header.scss'
import {classes} from './actions-menu-layout.st.css'

export const Header: React.FC = () => {
  const {name} = useWidgetState(state => state.session.session)
  const {fullScreen, layout} = useWidgetState(state => state.controls)
  const webinar = useWidgetState(isWebinar)
  const sidePanelState = useWidgetState(state => state.sidePanel)
  const activeParticipantCount = useWidgetState(getActiveParticipantCount)
  const settingsOpened = isCameraAndSoundSettingsOpened(sidePanelState)
  const participantsOpened = isParticipantsOpened(sidePanelState)
  const [expanded, setExpanded] = useState(false)
  const {toggleSidePanel, setLayout, setFullScreen, leaveSession: leaveSessionAction} = useActions()
  const {
    uouLiveVideoChangeLayout,
    uouLiveVideoSettingsToggle,
    uouLiveVideoParticipantsListToggle,
    uouLiveVideoLeaveClick,
  } = useWidgetBI()
  const {recorderJoined} = useRecorder()
  const {t} = useTranslation()

  const openSettings = () => {
    uouLiveVideoSettingsToggle(settingsOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.CAMERA_AND_SOUND)
  }

  const openParticipants = () => {
    uouLiveVideoParticipantsListToggle(participantsOpened ? 'off' : 'on')
    toggleSidePanel(SidePanelContent.PARTICIPANTS)
  }

  const toggleFullScreen = () => {
    const newFullScreen = !fullScreen

    setFullScreen(newFullScreen)

    if (screenfull.isEnabled) {
      newFullScreen ? screenfull.request() : screenfull.exit()
    }
  }

  const leaveSession = () => {
    uouLiveVideoLeaveClick()
    leaveSessionAction()
  }

  const options = [
    {
      id: LAYOUT.FOCUSED,
      value: t('speakerView'),
      onClick: () => {
        setExpanded(false)
        setLayout(LAYOUT.FOCUSED)
        uouLiveVideoChangeLayout('speaker')
      },
      activeIcon: expanded ? SpeakerViewClicked : SpeakerViewNotClicked,
      icon: <SpeakerViewPopover />,
    },
    {
      id: LAYOUT.GRID,
      value: t('gridView'),
      onClick: () => {
        setExpanded(false)
        setLayout(LAYOUT.GRID)
        uouLiveVideoChangeLayout('grid')
      },
      activeIcon: expanded ? GridClicked : GridNotClicked,
      icon: <GridPopover />,
    },
    {divider: true},
    fullScreen
      ? {
          id: 'exitFullScreen',
          value: t('exitFullScreen'),
          icon: <ExitFullScreen />,
          onClick: () => {
            setExpanded(false)
            toggleFullScreen()
          },
        }
      : {
          id: 'enterFullScreen',
          value: t('enterFullScreen'),
          icon: <EnterFullScreen />,
          onClick: () => {
            setExpanded(false)
            toggleFullScreen()
          },
        },
  ]

  const selectedActionMenuOption = options.find(option => option.id === layout)
  const focusedIndex = options.findIndex(option => option.id === selectedActionMenuOption.id)

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.text}>{name}</div>
        {recorderJoined && (
          <div className={s.recording}>
            <Recording animated />
            <div className={classNames(s.text, s.recordingText)}>{t('recording')}</div>
          </div>
        )}
      </div>
      <div className={s.right}>
        {webinar ? (
          <ControlButton
            square
            Icon={fullScreen ? ExitFullScreen : EnterFullScreen}
            text={fullScreen ? t('exitFullScreen') : t('enterFullScreen')}
            textClassName={s.controlButtonText}
            onClick={toggleFullScreen}
          />
        ) : (
          <Tooltip
            dataHook={DH.LAYOUT_TOGGLE}
            tooltipShown={expanded}
            className={s.tooltipContainer}
            showArrow={false}
            onVisibilityChange={() => setExpanded(!expanded)}
            placement="bottom-end"
            trigger="click"
            tooltip={
              <ActionsMenuLayout focusedIndex={focusedIndex} className={classes.root}>
                {options.map(({divider, onClick, value, icon}, index) =>
                  divider ? (
                    <ActionsMenuLayout.Divider key={index} />
                  ) : (
                    <ActionsMenuLayout.Item key={index} onClick={onClick} content={value} prefixIcon={icon} />
                  ),
                )}
              </ActionsMenuLayout>
            }
          >
            <ControlButton
              square
              Icon={selectedActionMenuOption.activeIcon}
              active={expanded}
              text={
                <>
                  {selectedActionMenuOption.value}
                  {expanded ? <ChevronUp /> : <ChevronDown />}
                </>
              }
              textClassName={s.controlButtonText}
            />
          </Tooltip>
        )}
        <ControlButton
          dataHook={DH.PARTICIPANTS_TOGGLE}
          square
          Icon={participantsOpened ? ViewersClicked : ViewersNotClicked}
          active={participantsOpened}
          text={activeParticipantCount}
          textClassName={s.controlButtonText}
          onClick={openParticipants}
        />
        <ControlButton
          dataHook={DH.SETTINGS_TOGGLE}
          square
          Icon={settingsOpened ? SettingsClicked : SettingsNotClicked}
          active={settingsOpened}
          onClick={openSettings}
        />
        <ControlButton
          className={s.leaveButton}
          dataHook={DH.LEAVE_BUTTON}
          square
          red
          Icon={Exit}
          text={t('leave')}
          textClassName={s.controlButtonText}
          onClick={leaveSession}
        />
      </div>
    </div>
  )
}
