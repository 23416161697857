import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import {getFormattedDate, getFormattedTime, addMinutes} from '@wix/live-video-commons'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {unblockAutoPlay} from '@wix/live-video-components'
import {TextField} from '../../common/text-field'
import {DH} from '../../../../data-hooks'
import {getLocale} from '../../../selectors/environment'
import {getInitialParticipantName} from '../../../selectors/participants'
import {getSession, isSessionStarted, isWebinar} from '../../../selectors/session'
import {useActions, useWidgetState} from '../../widget-state-provider'
import {useWidgetBI} from '../../../hooks/bi'
import {useMediaDevicesContext} from '../../media-devices-provider/media-devices-provider'
import {useModals} from '../../../hooks/modals'
import {ModalType} from '../../../interfaces'
import s from './waiting-form.scss'
import {ButtonWithStatus} from './button-with-status'
import {WaitingFormProps} from './index'

export const WaitingForm: React.FC<WaitingFormProps> = () => {
  const {isMobile: mobile} = useEnvironment()
  const webinar = useWidgetState(isWebinar)
  const initialParticipantName = useWidgetState(getInitialParticipantName)
  const {name: sessionName, startDate, duration} = useWidgetState(getSession)
  const locale = useWidgetState(getLocale)
  const [name, setName] = useState('')
  const [nameBlurred, setNameBlurred] = useState(false)
  const sessionStartDate = String(startDate)
  const formattedStartDate = getFormattedDate({locale, timestamp: sessionStartDate})
  const formattedStartTime = getFormattedTime({locale, timestamp: sessionStartDate})
  const formattedEndTime = getFormattedTime({locale, timestamp: addMinutes(sessionStartDate, duration)})
  const {t} = useTranslation()
  const {addParticipant} = useActions()
  const sessionStarted = useWidgetState(isSessionStarted)
  const {uouLiveVideoJoinClick} = useWidgetBI()
  const {unsupportedBrowser} = useMediaDevicesContext()
  const {showModal} = useModals()

  const nameEntered = Boolean(name.trim().length)

  useEffect(() => {
    setName(initialParticipantName)
  }, [initialParticipantName])

  const join = () => {
    if (unsupportedBrowser) {
      showModal(ModalType.UNSUPPORTED_BROWSER)
    } else if (sessionStarted && nameEntered) {
      unblockAutoPlay()
      addParticipant({name})
      uouLiveVideoJoinClick()
    }
  }

  return (
    <div className={classNames(mobile ? s.mobileContainer : s.container, {[s.webinar]: webinar})}>
      <div className={s.content}>
        <div className={s.title}>{sessionName}</div>
        <div className={s.dateTime}>{`${formattedStartDate}, ${formattedStartTime} - ${formattedEndTime}`}</div>
        <div className={s.label}>{t('waitingFormInputLabel')}</div>
        <TextField
          className={s.textField}
          data-hook={DH.NAME_INPUT}
          value={name}
          minLength={2}
          maxLength={64}
          placeholder={t('waitingFormInputPlaceholder')}
          autoFocus={!name}
          error={nameBlurred && !name.trim().length}
          errorMessage={t('fieldRequired')}
          onBlur={() => setNameBlurred(true)}
          onChange={event => setName(event.target.value)}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              join()
            }
          }}
        />
      </div>
      <ButtonWithStatus onClick={join} />
    </div>
  )
}
